import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";
import { headerFont, primaryFont, typeScale } from "theme";

const GlobalStyles = createGlobalStyle`
    ${normalize()}
    html {
        box-sizing: border-box;
        font-size: 50%;
        @media only screen and (max-width: 600px) {
            font-size: 62.5%;
        }
        @media only screen and (min-width: 992px) {
            font-size: 62.5%;
        }
    }
    *, *:before, *:after {
        box-sizing: border-box;
        -webkit-transition: all 0.10s ease-in-out;
        -moz-transition: all 0.10s ease-in-out;
        -ms-transition: all 0.10s ease-in-out;
        -o-transition: all 0.10s ease-in-out;
    }
    body {
        background: ${({ theme }) => theme.backgroundColor};
        color: ${({ theme }) => theme.textColor};
        transition: all 0.05s linear;
        width: 100%;
        height: 100vh;
        line-height: 3rem;
        font-family: ${primaryFont};
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        ::-webkit-scrollbar {
            width: 7px;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
        }
        ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.textColor};
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    p, a {
        font-size: ${typeScale.bodyText1};
        font-family: ${primaryFont};
        color: ${({ theme }) => theme.textColor};
    }
    h1, h2, h3, h4, h5 {
        font-family: ${headerFont};
        font-weight: 400;
        color: ${({ theme }) => theme.headerColor};
    }
    h1 {
        margin-bottom: 4.0rem;
        line-height: 4.3rem;
        font-size: ${typeScale.header1};
    }
    h2 {
        margin-bottom: 2.0rem;
        line-height: 2.8rem;
        font-size: ${typeScale.header2};
    }
    h3 {font-size: ${typeScale.header3};}
    h4 {font-size: ${typeScale.header4};}
    h5 {font-size: ${typeScale.header5};}
    .toggle {
        height: 8.4rem;
        padding: 2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .toggle-button {
            padding: 1rem;
            font-size: ${typeScale.bodyText3};
            background-color: transparent;
            color: ${({ theme }) => theme.textColor};
            border: none;
            cursor: pointer;
            svg {
                width: 2rem;
                height: 2rem;
            }
            &:hover {
            color: ${({ theme }) => theme.headerColor};
          }
        }
    }
`;

export default GlobalStyles;
