import { white, black } from "./colors";

export const lightTheme = {
  backgroundColor: white[100],
  headerColor: black[100],
  textColor: black[200],
  dividerColor: black[200],
  buttonBgColor: black[100],
  buttonColor: white[100]
};

export const darkTheme = {
  backgroundColor: black[100],
  headerColor: white[100],
  textColor: white[200],
  dividerColor: white[200],
  buttonBgColor: white[100],
  buttonColor: black[100]
};
