import React, { Component, Suspense } from "react";
import { Router as BrowerRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "theme";
import { FaSun, FaMoon, FaPlay, FaStop } from "react-icons/fa";
import BackgroundMusic from "assets/audio/background.mp3";
import { defaultRoute } from "routes/routes-list";
import history from "routes/history";
import Loading from "components/Loading";
import Main from "modules/Main";
import GlobalStyles from "./Global";

const audio = new Audio(BackgroundMusic);
audio.loop = true;

class App extends Component {
  state = {
    darkMode: false,
    isPlaying: false
  };
  componentDidMount() {
    this.handleDarkMode();
  }
  handleDarkMode = () => {
    const hours = new Date().getHours();
    this.setState({ darkMode: hours < 7 || hours > 19 });
  };
  toggleDarkMode = () => {
    this.setState({ darkMode: !this.state.darkMode });
  };
  render() {
    const { darkMode, isPlaying } = this.state;
    const { toggleDarkMode } = this;
    return (
      <ThemeProvider theme={darkMode === false ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Suspense fallback={<Loading />}>
          <BrowerRouter history={history}>
            <div className="toggle">
              <button
                className="toggle-button"
                onClick={() => {
                  if (!isPlaying) {
                    audio.loop = true;
                    audio.play();
                  } else {
                    audio.loop = false;
                    audio.pause();
                  }
                  this.setState({ isPlaying: !isPlaying });
                }}
              >
                {!isPlaying ? <FaPlay /> : <FaStop />}
              </button>
              <button className="toggle-button" onClick={toggleDarkMode}>
                {darkMode ? <FaSun /> : <FaMoon />}
              </button>
            </div>
            <Switch>
              <Route path={defaultRoute} component={Main} />
              <Redirect to={defaultRoute} />
            </Switch>
          </BrowerRouter>
        </Suspense>
      </ThemeProvider>
    );
  }
}

export default App;
